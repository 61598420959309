import DJZT from './sn-table-column-djzt';
const selfData = {
    loading: false,
    snTable: {

        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                label: "时间",
                prop: "FBSJ"
            }, {
                label: "温度(℃)",
                prop: "DQQW"
            }, {
                label: "湿度(%)",
                prop: "DQSD"
            }, {
                label: "风向",
                prop: "FX"
            }, {
                label: "风力",
                prop: "FL"
            }, {
                label: "降水量(mm)",
                prop: "JYL_24H"
            }, DJZT
            ],
            // 操作列
            columnsBtn: {
                buttonData: []
            }
        },
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
    },
    snButton: {
        buttonData: [
            {
                btnType: 'button',
                operateType: 'buttonExport',
                showMethod: 'showExportBtn',
                name: '导出',
                round: true,
                backColor: '#ffcc66',
                color: '#fff'
            }
        ]
    }
};
export default selfData;